import React, { FC } from 'react'
import Svg, { Path, SvgProps } from 'react-native-svg'

const FluentProjectionScreen: FC<{ fillColor?: string } & SvgProps> = ({
  fillColor = '#212121',
  ...props
}) => (
  <Svg
    width={24}
    height={24}
    fill='none'
    viewBox='0 0 24 24'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <Path
      d='M3.5 3A1.5 1.5 0 003 5.915v7.335A3.75 3.75 0 006.75 17h4.5v2.5h-3.5a.75.75 0 000 1.5h8.5a.75.75 0 000-1.5h-3.5V17h4.5A3.75 3.75 0 0021 13.25V5.915A1.5 1.5 0 0020.5 3h-17zm1 3h15v7.25a2.25 2.25 0 01-2.25 2.25H6.75a2.25 2.25 0 01-2.25-2.25V6z'
      fill={fillColor}
    />
  </Svg>
)
export default FluentProjectionScreen
